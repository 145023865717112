import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as RD from '@devexperts/remote-data-ts';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';

import { altOnError, fromOption } from '@utils/RemoteData';
import { profileSettingsSelector, useSettingsEq } from '@store/settings';
import { usePortfolioList } from '~/entities/protfolio/list';
import { setActivePortfolio } from '~/entities/protfolio';

const errorData = { code: 404, message: 'No portfolio ID set' };

export const useLoadActivePortfolio = () => {
  const dispatch = useDispatch();
  const initiallySetRef = useRef(false);

  const portfolioList = usePortfolioList();
  const settings = useSelector(profileSettingsSelector, useSettingsEq.equals);

  useEffect(() => {
    if (initiallySetRef.current) {
      return;
    }

    pipe(
      settings,
      RD.map(({ lastActivePortfolioId }) => O.fromNullable(lastActivePortfolioId)),
      RD.chain(fromOption(() => RD.failure(errorData))),
      altOnError(() =>
        pipe(
          portfolioList,
          RD.map(A.head),
          RD.chain(fromOption(() => RD.failure(errorData))),
          RD.map((firstPortfolio) => firstPortfolio.id),
        ),
      ),
      RD.map((activePortfolioId) => {
        initiallySetRef.current = true;
        dispatch(setActivePortfolio(activePortfolioId));
      }),
    );
  }, [dispatch, portfolioList, settings]);
};
