import loadable from '@loadable/component';

export { AccountPage as Account } from '~/ui/pages/Account';
export { FAQPage as FAQ } from '~/ui/pages/FAQ';
export { FundWithdrawPage as FundWithdraw } from '~/ui/pages/fund_withdraw';
export { PortfolioPage as MainPage } from '~/ui/pages/portfolio';
export { PerformancePage } from '~/ui/pages/performance';

export const ErrorPage = loadable(() => import('~/ui/pages/ErrorPage'));

export const ResetPassword = loadable(() => import('~/ui/pages/ForgotPassword/ResetPassword/ResetPassword'));
export const NewPassword = loadable(() => import('~/ui/pages/ForgotPassword/NewPassword/NewPassword'));
export const PageNotFound = loadable(() => import('~/ui/pages/PageNotFound/PageNotFound'));

export const OnboardingAlpaca = loadable(() => import('~/ui/pages/onboarding_alpaca/new2/Onboarding'));

export const KYC = loadable(() => import('~/ui/pages/onboarding_alpaca/KYC'));

export const OnboardingExternal = loadable(() => import('~/ui/pages/onboarding_external'));

export const AlpacaOnboarding = loadable(() => import('@pages/AlpacaOnboarding/AlpacaOnboarding'));

export const AlpacaOnboardingExternal = loadable(() => import('@pages/AlpacaOnboarding/ExternalAlpacaOnboarding'));
export const NewPortfolio = loadable(() => import('@pages/NewPortfolio'));
