export const GET_STARTED_EXTERNAL = '/external/get-started';

export const MAIN = '/';

export const LOGIN = '/login';
export const RESET_PASSWORD = '/reset-password';
export const NEW_PASSWORD = '/new-password';

export const FAQ = '/faq';
export const NEW_ACCOUNT = '/account';

export const PORTFOLIO = '/portfolio';
export const FUND_WITHDRAW = '/fund_withdraw';
export const ONBOARDING_ALPACA = '/onboarding-alpaca';
export const KYC = '/kyc';
export const CREATE_PORTFOLIO = '/create-portfolio';
export const ERROR_PAGE = '/error-page';

export const ANY = '*';
