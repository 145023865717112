import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import * as routes from './names';
import * as components from './components';

import UnrestrictedRoute from '~/authentication/UnrestrictedRoute';
import PrivateRoute from '~/authentication/PrivateRoute';

import SideBarLayout from '~/ui/layouts/SideBarLayout';
import FullScreenLayout from '~/ui/layouts/FullScreenLayout';

export default [
  {
    hasAccess: () => true,
    getComponent: () => (
      <UnrestrictedRoute
        key={routes.ERROR_PAGE}
        path={routes.ERROR_PAGE}
        component={(props) => (
          <FullScreenLayout>
            <components.ErrorPage key={routes.ERROR_PAGE} {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
  {
    hasAccess: () => true,
    getComponent: () => (
      <UnrestrictedRoute
        key={routes.RESET_PASSWORD}
        path={routes.RESET_PASSWORD}
        component={(props) => (
          <FullScreenLayout>
            <components.ResetPassword key={routes.RESET_PASSWORD} {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
  {
    hasAccess: () => true,
    getComponent: () => (
      <UnrestrictedRoute
        key={routes.NEW_PASSWORD}
        path={routes.NEW_PASSWORD}
        component={(props) => (
          <FullScreenLayout>
            <components.NewPassword key={routes.NEW_PASSWORD} {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ hasAlpacaAccount, closure }) => hasAlpacaAccount && closure,
    getComponent: ({ hasAlpacaAccount }) => (
      <PrivateRoute
        exact
        key={routes.MAIN + 'hasAlpacaClosure'}
        path={routes.MAIN}
        component={(props) => (
          <SideBarLayout>
            <components.Account key={routes.MAIN} hasAlpacaAccount={hasAlpacaAccount} {...props} />
          </SideBarLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ closure }) => !closure,
    getComponent: () => (
      <PrivateRoute
        exact
        key={routes.MAIN + '!closure'}
        path={routes.MAIN}
        component={(props) => (
          <SideBarLayout>
            <components.PerformancePage key={routes.MAIN} {...props} />
          </SideBarLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ closure }) => !closure,
    getComponent: ({ hasAlpacaAccount }) => (
      <PrivateRoute
        exact
        key={routes.PORTFOLIO}
        path={routes.PORTFOLIO}
        component={(props) => (
          <SideBarLayout>
            <components.MainPage key={routes.PORTFOLIO} hasAlpacaAccount={hasAlpacaAccount} {...props} />
          </SideBarLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ closure }) => !closure,
    getComponent: ({ hasAlpacaAccount }) => (
      <PrivateRoute
        exact
        key={routes.FUND_WITHDRAW}
        path={routes.FUND_WITHDRAW}
        additionalPermissions={hasAlpacaAccount}
        noAdditionalPermissionsComponent={(props) => (
          <Redirect
            to={{
              pathname: routes.ONBOARDING_ALPACA,
              state: { from: props.location },
            }}
          />
        )}
        component={(props) => (
          <SideBarLayout>
            <components.FundWithdraw key={routes.FUND_WITHDRAW} {...props} />
          </SideBarLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ hasAlpacaAccount }) => !hasAlpacaAccount,
    getComponent: () => (
      <PrivateRoute
        path={routes.MAIN}
        key={routes.MAIN + '!hasAlpacae'}
        exact
        component={(props) => (
          <SideBarLayout>
            <components.MainPage key={routes.MAIN} {...props} />
          </SideBarLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ hasAlpacaAccount: _, closure: __ }) => true,
    getComponent: ({ hasAlpacaAccount }) => (
      <PrivateRoute
        exact
        key={routes.NEW_ACCOUNT}
        path={routes.NEW_ACCOUNT}
        component={(props) => (
          <SideBarLayout>
            <components.Account key={routes.NEW_ACCOUNT} hasAlpacaAccount={hasAlpacaAccount} {...props} />
          </SideBarLayout>
        )}
      />
    ),
  },
  {
    hasAccess: () => true,
    getComponent: () => (
      <PrivateRoute
        exact
        key={routes.FAQ}
        path={routes.FAQ}
        component={(props) => (
          <SideBarLayout>
            <components.FAQ key={routes.FAQ} {...props} />
          </SideBarLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ hasAlpacaAccount }) => !hasAlpacaAccount,
    getComponent: () => (
      <PrivateRoute
        exact
        key={routes.ONBOARDING_ALPACA}
        path={routes.ONBOARDING_ALPACA}
        component={(props) => (
          <FullScreenLayout>
            <components.OnboardingAlpaca key={routes.ONBOARDING_ALPACA} {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ hasAlpacaAccount }) => !hasAlpacaAccount,
    getComponent: () => (
      <Route
        exact
        key="onboarding-alpaca-to-test"
        path="/onboarding-alpaca-to-test"
        component={(props) => (
          <FullScreenLayout>
            <components.AlpacaOnboarding key="onboarding-alpaca-to-test" {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ hasAlpacaAccount, isExternalUser }) => !hasAlpacaAccount && isExternalUser,
    getComponent: () => (
      <Route
        exact
        key="/onboarding-alpaca-to-test-external"
        path="/onboarding-alpaca-to-test-external"
        component={(props) => (
          <FullScreenLayout>
            <components.AlpacaOnboardingExternal key="/onboarding-alpaca-to-test-external" {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ closure }) => !closure,
    getComponent: () => (
      <Route
        exact
        key={routes.CREATE_PORTFOLIO}
        path={routes.CREATE_PORTFOLIO}
        component={(props) => (
          <FullScreenLayout>
            <components.NewPortfolio key={routes.CREATE_PORTFOLIO} {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
  {
    hasAccess: ({ hasAlpacaAccount, isExternalUser }) => !hasAlpacaAccount && isExternalUser,
    getComponent: () => (
      <UnrestrictedRoute
        exact
        key={routes.GET_STARTED_EXTERNAL}
        path={routes.GET_STARTED_EXTERNAL}
        component={(props) => (
          <FullScreenLayout>
            <components.OnboardingExternal key={routes.GET_STARTED_EXTERNAL} {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
  {
    hasAccess: () => true,
    getComponent: () => (
      <PrivateRoute
        exact
        key={routes.KYC}
        path={routes.KYC}
        component={(props) => (
          <FullScreenLayout>
            <components.KYC key={routes.KYC} {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
  {
    hasAccess: () => true,
    getComponent: () => (
      <Route
        key={routes.ANY}
        path={routes.ANY}
        component={(props) => (
          <FullScreenLayout>
            <components.PageNotFound key={routes.ANY} {...props} />
          </FullScreenLayout>
        )}
      />
    ),
  },
];
