import { lazy } from 'react';
import * as ROUTES from './names';

export const fundedLinks = [
  {
    path: ROUTES.MAIN,
    title: 'Performance',
    component: lazy(() => import('@components/icons/Performance')),
  },
  {
    path: ROUTES.PORTFOLIO,
    title: 'Portfolio',
    component: lazy(() => import('@components/icons/Portfolio')),
  },
  {
    path: ROUTES.FUND_WITHDRAW,
    title: 'Fund / Withdraw',
    component: lazy(() => import('@components/icons/FundWithdraw')),
  },
  {
    path: ROUTES.NEW_ACCOUNT,
    title: 'Account',
    component: lazy(() => import('@components/icons/Settings')),
  },
];

export const closureLinks = [
  {
    path: ROUTES.NEW_ACCOUNT,
    title: 'Account',
    component: lazy(() => import('@components/icons/Settings')),
  },
];

export const FAQMenuItem = {
  path: ROUTES.FAQ,
  title: 'Knowledge center',
  component: lazy(() => import('@components/icons/Questions')),
};

export const getMenuItems = (closure: boolean) => (closure ? closureLinks : fundedLinks);
