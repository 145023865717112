import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectOrganization } from '@store/organization/selectors';
import { mapPortfolioTypeArrayDto } from '~/entities/protfolio/list';

export const useAllowedPortfolioTypes = () => {
  const organizationInfo = useSelector(selectOrganization);

  return useMemo(
    () =>
      organizationInfo && organizationInfo.allowed_portfolio_types
        ? mapPortfolioTypeArrayDto(organizationInfo.allowed_portfolio_types)
        : [],
    [organizationInfo],
  );
};
